import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";
import { CertificateType } from "./Enums";
import { LookupItem } from "./LookupItem";

export interface ICertificate {
    id: number;
    supplierID: number;
    supplierResponseSetID: number;
    supplierName: string;
    supplierAddress: string;
    certificateType: CertificateType;
    schemeName: string;
    trade: string;
    cdmCategory: string;
    expiry: Date;
    approval: Date;
    numberOfPersonnel: number;
    visibleToBuyerIDs: Array<number>;
    created: Date;
    createdByUserID: number;
    lastModified: Date;
    lastModifiedByUserID: number;
    archived: Date;
    archivedByUserID: number;
}

export class Certificate implements ICertificate {

    constructor(data?: ICertificate) {
        if (data) this.update(data);
    }

    update(data: ICertificate) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    supplierID: number = 0;
    supplierResponseSetID: number = 0;
    supplierName: string = "";
    supplierAddress: string = "";
    certificateType: CertificateType = CertificateType.None;
    schemeName: string = "";
    trade: string = "";
    cdmCategory: string = "";
    expiry: Date = new Date(utils.emptyDateValue);
    approval: Date = new Date(utils.emptyDateValue);
    numberOfPersonnel: number = 0;
    visibleToBuyerIDs: Array<number> = [];
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastModified: Date = new Date(utils.emptyDateValue);
    lastModifiedByUserID: number = 0;
    archived: Date = new Date(utils.emptyDateValue);
    archivedByUserID: number = 0;

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isArchived() {
        return utils.hasDateValue(this.archived);
    }

    get number() {
        return utils.pad(this.supplierID, 4) + "-" + utils.pad(this.id, 3);
    }

    get description() {
        return this.supplierName + " - " + this.number;
    }

    get expired(): boolean {
        return utils.hasDateValue(this.expiry) && this.expiry < new Date();
    }
}

export function certificateTypeOptions(defaultText: string): Array<LookupItem> { 
    return [
        new LookupItem({ id: CertificateType.None, description: defaultText, isArchived: false }),
        new LookupItem({ id: CertificateType.CasAssessment, description: "CAS Desktop", isArchived: false }),
        new LookupItem({ id: CertificateType.PasVerification, description: "DTS (PAS91)", isArchived: false }),
        new LookupItem({ id: CertificateType.SsipVerification, description: "DTS (SSIP)", isArchived: false }),
        new LookupItem({ id: CertificateType.CsrVerification, description: "DTS (Vistry CSR)", isArchived: false }),
        new LookupItem({ id: CertificateType.PasAssessment, description: "Assessment (PAS91)", isArchived: false }),
        new LookupItem({ id: CertificateType.SsipAssessment, description: "Assessment (SSIP)", isArchived: false }),
        new LookupItem({ id: CertificateType.CsrAssessment, description: "Assessment (Vistry CSR)", isArchived: false })
    ];
}